import analyticsUtils from '../analytics-utils'
import trackClickEvent from '../analytics-utils/track-event'

export const createProductClickEvent = (productName, feature, label) => {
  if (productName === analyticsUtils.categories.DIGITAL_GIFT_CARD) {
    analyticsUtils.DEPRECATED_gaTagTracking({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: 'digital gift cards' },
          products: [
            {
              name: 'digital gift card',
              id: 'card1001',
              category: 'digital',
              brand: 'westfield',
              position: feature,
              list: 'digital gift cards',
            },
          ],
        },
      },
    })
  } else if (productName === analyticsUtils.categories.GIFTCARD_CLICK) {
    trackClickEvent(
      productName,
      analyticsUtils.categories.COMPARISON_MODULE_CTA,
      label
    )
  }
}
