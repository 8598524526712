/* eslint jsx-a11y/no-redundant-roles: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Paragraph } from '@scentregroup/ui'
import { Box } from '@hub/box'
import { occurrencesType } from '../../../types'
import { summariseOccurrences } from '../../../helpers/occurrences'
import AutoPotentialLink from '../../auto-potential-link'
import ResponsiveImage from '../../responsive-image'
import Trackable from '../../trackable'
import { trackingActions, trackingTypes } from '../../../constants'
import MemberExclusiveLabel from '../../member-exclusive-label'
import { isMemberExclusive } from '../../../helpers/is-member-exclusive'
import style from './index.module.scss'

const DeckCard = ({
  className,
  body,
  image,
  link,
  externalLink,
  occurrences,
  location,
  onClick,
  analyticsMetadata,
  highlightAttribute,
  padded = false,
  trackers,
}) => {
  const hasOccurrences = Boolean(
    occurrences && occurrences.times && occurrences.times.length
  )

  return (
    <li
      className={classNames(
        style.DeckCard,
        style.evolved,
        { [style.padded]: padded },
        className
      )}
      role="listitem"
      data-test-id="card"
    >
      <Trackable
        {...analyticsMetadata}
        trackingAction={trackingActions.viewPromotion}
        type={trackingTypes.promotion}
        creative={analyticsMetadata?.promotion?.creative}
      >
        <AutoPotentialLink
          link={link}
          className={style.link}
          external={externalLink}
          onClick={() => {
            onClick()
            trackers?.clicked?.(body)
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <ResponsiveImage
              className={style.image}
              desktopImageUrl={image}
              mobileImageUrl={image}
              mobileRatio="square"
              desktopRatio="square"
              numberOfItems={4}
              alt=""
              dataTestId="card-image"
            />
            <MemberExclusiveLabel
              sx={{
                position: 'absolute',
                width: '91px',
                bottom: 0,
              }}
              display={isMemberExclusive(highlightAttribute)}
              option="small"
            />
          </Box>
          <div className={style.cardBody}>
            <div className={style.cardInner} data-test-id="card-details">
              {(hasOccurrences || location) && (
                <div className={style.metaWrapper}>
                  {hasOccurrences && (
                    <Paragraph className={style.meta}>
                      {summariseOccurrences(
                        occurrences.times,
                        occurrences.timeZone
                      )}
                    </Paragraph>
                  )}
                  {location && (
                    <Paragraph className={style.meta}>{location}</Paragraph>
                  )}
                </div>
              )}
              <Paragraph className={style.description}>{body}</Paragraph>
            </div>
          </div>
        </AutoPotentialLink>
      </Trackable>
    </li>
  )
}

DeckCard.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  link: PropTypes.string,
  externalLink: PropTypes.bool,
  occurrences: occurrencesType,
  location: PropTypes.string,
  onClick: PropTypes.func,
  analyticsMetadata: PropTypes.shape({
    correlationId: PropTypes.string,
    title: PropTypes.string,
    promotion: PropTypes.shape({
      creative: PropTypes.string,
    }),
  }),
  padded: PropTypes.bool,
  memberExclusive: PropTypes.bool,
  highlightAttribute: PropTypes.oneOf(['"MEMBEREXCLUSIVE"']),
  trackers: PropTypes.shape({
    clicked: PropTypes.func,
  }),
}

export default DeckCard
