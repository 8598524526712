/* eslint jsx-a11y/no-redundant-roles: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container, Heading } from '@scentregroup/ui'
import { uniqueId } from 'lodash'
import DeckScrollWrapper from '../deck-scroll-wrapper'
import analyticsUtils from '../../helpers/analytics-utils'
import style from './index.module.scss'

class FeaturedContent extends Component {
  trackClickEvent = item => {
    analyticsUtils.DEPRECATED_gaTagTracking({
      event: analyticsUtils.events.PROMOTION_CLICK,
      ecommerce: {
        promoClick: {
          promotions: {
            id: item.id,
            name: item.title,
            position: analyticsUtils.positions.FEATURED_CONTENT,
            dimension90: analyticsUtils.dimensions.DIMENSION90,
            creative: item.body,
          },
        },
      },
    })
  }

  render() {
    const { children, title, padded = false } = this.props
    const id = uniqueId('featured-content-')
    return (
      <Container
        centred
        type="full"
        className={classNames(style.deckContainer, style.evolved)}
      >
        {title && (
          <Heading type={'megasmalldivider'} className={style.heading} id={id}>
            {title}
          </Heading>
        )}
        <DeckScrollWrapper
          contentClassName={style.featuredContentScroll}
          className={style.contentWrapper}
          useCosmeticScrollbar={false}
          eventCategory={analyticsUtils.categories.WHATS_HAPPENING_FEATURED}
          eventAction={analyticsUtils.actions.CLICK}
        >
          <ol className={style.deckCardList} aria-labelledby={id} role="list">
            {React.Children.map(children, child =>
              React.cloneElement(child, {
                className: style.deckCard,
                onClick: () => this.trackClickEvent(child.props),
                padded,
              })
            )}
          </ol>
        </DeckScrollWrapper>
      </Container>
    )
  }
}

FeaturedContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  padded: PropTypes.bool,
}

export default FeaturedContent
