import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@scentregroup/ui'
import classNames from 'classnames'
import AutoPotentialLink from '../auto-potential-link'
import ResponsiveImage from '../responsive-image'
import Trackable from '../trackable'
import { trackingActions, trackingTypes } from '../../constants'
import style from './index.module.scss'

const FlipFlop = ({
  children,
  imageUrl,
  position,
  size,
  link,
  className,
  analyticsMetadata,
  heading,
  lazyLoad = true,
}) => {
  const image = (
    <ResponsiveImage
      lazyLoad={lazyLoad}
      className={style.image}
      desktopImageUrl={imageUrl}
      mobileImageUrl={imageUrl}
      mobileRatio="standard"
      desktopRatio="standard"
      numberOfItems={2}
      alt=""
    />
  )

  const aria = {}
  if (heading) {
    aria['aria-label'] = heading
  }

  return (
    <Trackable
      trackingAction={trackingActions.viewPromotion}
      type={trackingTypes.promotion}
      {...analyticsMetadata}
    >
      <Container
        centred
        type={size}
        className={classNames(style.FlipFlop, style[position], className)}
      >
        <AutoPotentialLink link={link} {...aria}>
          {image}
        </AutoPotentialLink>
        {children}
      </Container>
    </Trackable>
  )
}

FlipFlop.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
  imageUrl: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.string,
  lazyLoad: PropTypes.bool,
  className: PropTypes.string,
  analyticsMetadata: PropTypes.shape({
    correlationId: PropTypes.string,
    title: PropTypes.string,
    promotion: PropTypes.shape({
      creative: PropTypes.string,
    }),
  }),
  heading: PropTypes.string,
}

export default FlipFlop
