import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@scentregroup/ui'
import { HalfHalf } from '../../half-half'
import { DeckCard } from '../../deck'
import FeaturedContent from '../../featured-content'
import Promotion from '../../promotion'
import ComparisonItem from '../../comparison-item'
import { PromotionPositionContext } from '../../../context'

const Module = ({ moduleProps }) => {
  switch (moduleProps.displayMode) {
    case 'Featured Content':
      return (
        <PromotionPositionContext.Provider
          value={moduleProps?.analyticsMetadata?.displayId}
        >
          <FeaturedContent color={moduleProps.color} title={moduleProps.title}>
            {(moduleProps.entries || []).map(entry => (
              <DeckCard {...entry} key={entry.id} />
            ))}
          </FeaturedContent>
        </PromotionPositionContext.Provider>
      )

    case 'Promotion':
      return (
        <Container centred type="large">
          <Promotion {...moduleProps} />
        </Container>
      )

    case 'Comparison Item':
      return (
        <Container centred type="large">
          <HalfHalf>
            {moduleProps.entries.map(entry => (
              <ComparisonItem {...entry} key={entry.productName} />
            ))}
          </HalfHalf>
        </Container>
      )
    default:
      console.log('unsupported display mode:', moduleProps)
  }
}

Module.propTypes = {
  moduleProps: PropTypes.object,
  resetModulesTemplate: PropTypes.func,
  lazyLoad: PropTypes.bool,
}

export default Module
