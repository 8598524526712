import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import classNames from 'classnames'
import ContentGroup from '../content-group'
import { CustomThemeContext } from '../../context'
import Module from './module'
import style from './index.module.scss'

const Modules = ({ className, modules, centreTitle }) => {
  const color = 'Transparent'
  const angle = 'none'
  return (
    <CustomThemeContext.Consumer>
      {() => (
        <div
          className={classNames(className, style.moduleGroup, style.evolved)}
        >
          {modules.map((module, i) => {
            const moduleId = get(module, 'sys.id')

            return (
              <ContentGroup
                angleOrigin={angle}
                color={color}
                backgroundImage={false}
                key={`module-${moduleId || i}`}
                // Need to pass a different key for when arbitary react Children are sent
              >
                <Module
                  lazyLoad={i > 0}
                  moduleProps={{
                    ...module,
                    angleOrigin: angle,
                    color,
                    centreTitle,
                  }}
                />
              </ContentGroup>
            )
          })}
        </div>
      )}
    </CustomThemeContext.Consumer>
  )
}

Modules.propTypes = {
  modules: PropTypes.array,
  initialAngle: PropTypes.string,
  initialColor: PropTypes.string,
  className: PropTypes.string,
  centreTitle: PropTypes.string,
  basicContentFullWidthCtaOnMobile: PropTypes.bool,
  children: PropTypes.element,
}

export default Modules
