import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import style from './index.module.scss'
import Half from './half'

const HalfHalf = ({ children }) => (
  <div className={classNames(style.HalfHalf, style.evolved)}>{children}</div>
)

HalfHalf.propTypes = {
  children: PropTypes.node,
}

export { Half, HalfHalf }
