import React from 'react'
import PropTypes from 'prop-types'
import { Paragraph } from '@scentregroup/ui'
import PotentialLink from '../../auto-potential-link'
import Article from '../../article'
import ResponsiveImage from '../../responsive-image'
import Trackable from '../../trackable'
import { trackingActions, trackingTypes } from '../../../constants'
import style from './index.module.scss'

const Half = ({
  imageUrl,
  link,
  topic,
  title,
  body,
  analyticsMetadata,
  lazyLoad = true,
  ...articleProps
}) => (
  <Trackable
    {...analyticsMetadata}
    trackingAction={trackingActions.viewPromotion}
    type={trackingTypes.promotion}
    creative={analyticsMetadata?.promotion?.creative}
  >
    {imageUrl && link && (
      <PotentialLink link={link}>
        <ResponsiveImage
          lazyLoad={lazyLoad}
          angleOrigin={undefined}
          className={style.Image}
          desktopImageUrl={imageUrl}
          mobileImageUrl={imageUrl}
          mobileRatio="standard"
          desktopRatio="standard"
          numberOfItems={2}
          alt={title}
        />
      </PotentialLink>
    )}
    {imageUrl && !link && (
      <ResponsiveImage
        lazyLoad={lazyLoad}
        angleOrigin={undefined}
        className={style.Image}
        desktopImageUrl={imageUrl}
        mobileImageUrl={imageUrl}
        mobileRatio="standard"
        desktopRatio="standard"
        numberOfItems={2}
        alt={title}
      />
    )}
    <Article
      superheading={topic}
      heading={title}
      headingType="medium"
      link={link}
      context="half"
      {...articleProps}
    >
      <Paragraph>{body}</Paragraph>
    </Article>
  </Trackable>
)

Half.propTypes = {
  imageUrl: PropTypes.string,
  link: PropTypes.string,
  topic: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  articleProps: PropTypes.object,
  lazyLoad: PropTypes.bool,
  analyticsMetadata: PropTypes.shape({
    correlationId: PropTypes.string,
    title: PropTypes.string,
    promotion: PropTypes.shape({
      creative: PropTypes.string,
    }),
  }),
}

export default Half
